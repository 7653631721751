




































import { Component, Vue, Watch } from 'vue-property-decorator'
import ChartView from '@/components/atoms/Chart.vue'
import _ from 'lodash'
import * as MomentOrg from 'moment'
import { extendMoment } from 'moment-range'
import { ChartConfiguration, ChartOptions, ChartLayoutPaddingObject, ChartDataSets, Chart, ChartPoint } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import BadgeButton from '@/components/atoms/BadgeButton.vue'
import VABox from 'va/widgets/VABox.vue'
import bodyTempTranditionsStore, { TUserBodyTempTrandition, THistoryInfo } from '@/store/BodyTempTranditions'
import conditionsStore, { GraphDateScopeType, GraphDateScope } from '@/store/Conditions'
import menuStore from '@/store/Menu'
import dayjs from '@/libs/dayjs'

@Component({
  components: { VABox, BadgeButton, ChartView },
})
export default class extends Vue {
  public get user(): { id: string; name: string; profilePhoto?: string } | undefined {
    const user = _.find(menuStore.persons, { active: true })
    if (!user) return undefined
    return {
      id: String(user.id),
      name: user.name,
      profilePhoto: user.profilePhoto,
    }
  }

  averageTemperature = 0
  /** デフォルト体温 */
  defaultTemp = DEFAULT_TEMP
  /** 最低体温 */
  min = DEFAULT_MIN_TEMP
  /** 最高体温 */
  max = DEFAULT_MAX_TEMP
  /** 平均体温の表示状態 */
  isAverageTemperatureVisible = false
  /** 体温およびチャート表示形式 */
  datasets: ChartDataSets[] = []
  /** 日付ラベル */
  labels: string[] | string[][] = []
  /** 体温データ */
  histories: THistoryInfo[] = []
  /** 表示体温の期間(YYYY/MM/DD-YYYY/MM/DD) */
  dateRange = ''
  /** 体温データおよび平均体温 */
  data: TUserBodyTempTrandition = DEFAULT_DATA
  /** 問診票や接触フラグなどのアイコン */
  pinkIconList: HTMLImageElement[] = []
  yellowIconList: HTMLImageElement[] = []
  blueIconList: HTMLImageElement[] = []
  blackIconList: HTMLImageElement[] = []
  /** チャート上で選択中のポイントINDEX */
  private selectedPointIndex?: number
  /** チャートの基本表示設定 */
  options: ChartOptions = {
    responsive: true,
    tooltips: { enabled: false },
    layout: { padding: { top: 10, left: 10, right: 40, bottom: 10 } },
    legend: { display: false },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        font: { size: 12 },
        textAlign: 'end',
        color: (context: ScriptContext): string => this.getPointBackgroundColor(context),
        // 平均体温：右 その他: 下
        align: (context: ScriptContext): 'right' | 'bottom' => (context.datasetIndex === 1 ? 'right' : 'bottom'),
        anchor: 'end',
        // 平均体温：4 その他:20
        offset: (context: ScriptContext): number => (context.datasetIndex === 1 ? 8 : 20),
        // デフォルト体温の場合、数字を非表示にする
        display: (context: ScriptContext): boolean => this.displayDatalabels(context),
        formatter: (value: { x: number; y: number }, context: ScriptContext): string[] =>
          this.formatterDatalabels(value, context),
      },
    },
    scales: { yAxes: [], xAxes: [] },
  }
  startDate = dayjs()
  endDate = dayjs()
  GraphDateScope = GraphDateScope

  get graphDateScope(): GraphDateScopeType {
    return conditionsStore.graphDateScope
  }

  async goBackward(): Promise<void> {
    this.endDate = this.startDate.add(-1, 'd')
    await this.fetch()
  }

  async goForward(): Promise<void> {
    switch (this.graphDateScope) {
      case GraphDateScope.DAY: {
        this.endDate = this.endDate.add(1, 'd')
        break
      }
      case GraphDateScope.MONTH: {
        this.endDate = this.endDate.add(1, 'M')
        break
      }
      default: {
        this.endDate = this.endDate.add(7, 'd')
      }
    }

    if (this.isFuture) {
      this.endDate = dayjs()
    }

    await this.fetch()
  }

  get isLatest(): boolean {
    return this.endDate.isSame(dayjs(), 'day')
  }

  get isFuture(): boolean {
    return this.endDate.isAfter(dayjs(), 'day')
  }

  async changeUnit(scope: GraphDateScopeType): Promise<void> {
    conditionsStore.setGraphDateScope(scope)
    await this.fetch()
  }

  @Watch('$route')
  async beforeRouteUpdate(): Promise<void> {
    await this.fetch()
  }

  async mounted(): Promise<void> {
    await this.fetch()
  }

  async fetch(): Promise<void> {
    this.$route.params.id
    conditionsStore.setConditions([])

    let dateFormat = ''
    let options = {}
    switch (this.graphDateScope) {
      case GraphDateScope.DAY: {
        this.startDate = this.endDate
        dateFormat = 'YYYY/MM/DD HH:mm:ss'
        options = { userId: this.$route.params.id }
        this.dateRange = this.startDate.format(YMD)
        break
      }
      case GraphDateScope.MONTH: {
        this.startDate = this.endDate.add(-1, 'M').add(1, 'd')
        dateFormat = 'YYYY/MM/DD 00:00:00'
        options = { userId: this.$route.params.id }
        this.dateRange = this.startDate.format(YMD) + '-' + this.endDate.format(YMD)
        break
      }
      default: {
        this.startDate = this.endDate.add(-6, 'd')
        dateFormat = 'YYYY/MM/DD 00:00:00'
        options = { userId: this.$route.params.id }
        this.dateRange = this.startDate.format(YMD) + '-' + this.endDate.format(YMD)
      }
    }

    // テーブル表示用のデータも取得する
    conditionsStore.fetchForPerson({ startDate: this.startDate, endDate: this.endDate, userId: this.$route.params.id })
    await bodyTempTranditionsStore.fetch({ startDate: this.startDate, endDate: this.endDate, options })
    const userBodyTempTrandition = bodyTempTranditionsStore.getUserBodyTempTrandition
    if (userBodyTempTrandition) {
      this.data = {
        ...userBodyTempTrandition,
        history: userBodyTempTrandition.history.map((history) => ({
          ...history,
          dateTime: history.dateTime.format(dateFormat),
        })),
      }
    } else {
      this.data = DEFAULT_DATA
    }

    this.loadIcon()

    this.selectedPointIndex = undefined

    // 日表示だとX軸ラベルの開業がないのでその分パディングしておく
    const bottom = this.graphDateScope === GraphDateScope.DAY ? 24 : 10
    if (this.options.layout?.padding) {
      this.options.layout.padding = { ...(this.options.layout.padding as ChartLayoutPaddingObject), bottom }
    }
    if (this.dateRange.length > 0) {
      // データをリセットする
      this.datasets = []
      this.labels = []
      this.histories = []
      switch (this.graphDateScope) {
        case GraphDateScope.DAY:
          this.createDayData()
          break
        case GraphDateScope.WEEK:
          this.createData()
          break
        case GraphDateScope.MONTH:
          this.createData()
          break
      }
      this.changeChartOptions()
    }
  }

  get chartConfig(): ChartConfiguration {
    return {
      type: 'line',
      data: {
        datasets: this.datasets,
        labels: this.labels,
      },
      options: this.options,
      plugins: [ChartDataLabels],
    }
  }

  loadIcon(): void {
    PointPinkImageList.forEach((url: string) => {
      let image = new Image()
      image.src = url
      this.pinkIconList.push(image)
    })
    PointYellowImageList.forEach((url: string) => {
      let image = new Image()
      image.src = url
      this.yellowIconList.push(image)
    })
    PointBlueImageList.forEach((url: string) => {
      let image = new Image()
      image.src = url
      this.blueIconList.push(image)
    })
    PointBlackImageList.forEach((url: string) => {
      let image = new Image()
      image.src = url
      this.blackIconList.push(image)
    })
  }

  /**
   * 登録された体温によって〇の色が変わる\
   * (青)～36.9℃／(黄)37.0～37.4℃／(ピンク)37.5℃～／(グレー)未登録
   */
  getPointBackgroundColor(context: ScriptContext): string {
    if (!context.dataset?.data || typeof context.dataIndex !== 'number') return 'transparent'
    // 平均体温
    if (context.datasetIndex === 1) {
      return '#fc8c04'
    }

    const index = context.dataIndex
    const data = context.dataset.data as ChartPoint[]
    const y = +(data[index].y as number)
    const temperature = y
    if (temperature >= 37.5) {
      // (ピンク)37.5℃～
      return '#ec0c74'
    } else if (temperature >= 37.0 && temperature <= 37.4) {
      // (黄)37.0～37.4℃ または、平均体温+1度
      return '#fcd907'
    } else if (this.averageTemperature > 0 && temperature >= this.averageTemperature + 1.0) {
      // (黄)平均体温 + 1.0以上
      return '#fcd907'
    } else if (temperature > this.defaultTemp && temperature <= 36.9) {
      // (青)～36.9℃
      return '#0f74fc'
    } else {
      // (グレー)未登録
      return 'transparent'
    }
  }

  /** ポイントの下に値・平均の表示/非表示 */
  displayDatalabels(context: ScriptContext): boolean {
    if (!context.dataset?.data || typeof context.dataIndex !== 'number') return false
    const index = context.dataIndex
    const data = context.dataset.data as ChartPoint[]
    const y = +(data[index].y as number)
    // 実際体温（デフォルト以外）
    if (this.graphDateScope !== GraphDateScope.MONTH && context.datasetIndex === 0 && y > this.defaultTemp) {
      return true
    } else if (context.datasetIndex === 1) {
      // 平均体温(最右端)
      if (context.dataIndex === context.dataset.data.length - 1) {
        return true
      }
    }
    return false
  }

  /** ポイントの下に値・平均のフォーマット */
  formatterDatalabels(value: { x: number; y: number }, context: ScriptContext): string[] {
    if (!context.dataset?.data || typeof context.dataIndex !== 'number') return ['']
    // 実際体温
    if (context.datasetIndex === 0) {
      return [`${(+value.y).toFixed(1)}`]
      // 平均体温(最右端)
    } else if (context.datasetIndex === 1) {
      if (!this.isAverageTemperatureVisible) {
        // 平均体温が有効な値ではないので空文字
        return ['']
      } else if (context.dataIndex === context.dataset.data.length - 1) {
        return ['平均', `${(+value.y).toFixed(1)}`]
      }
    }
    return ['']
  }

  /** グラフのオプションを更新する */
  changeChartOptions(): void {
    if (!this.options.scales) return
    // 横軸
    if (this.graphDateScope === GraphDateScope.DAY) {
      this.options.scales.xAxes = [
        {
          gridLines: {
            // X軸のボーダーのみを表示 (グリッド線なし)
            tickMarkLength: 0,
            color: '#000000',
            drawOnChartArea: false,
          },
          type: 'time',
          time: {
            unit: 'hour',
            displayFormats: {
              hour: 'HH:mm',
            },
            stepSize: 6,
            parser: 'YYYY/MM/DD HH:mm:ss',
          },
          ticks: {
            padding: 10,
            // 横書き
            maxRotation: 0,
            minRotation: 0,
            source: 'labels',
            fontColor: '#000000',
            fontFamily: 'Noto Sans JP',
            autoSkip: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            callback: (value: string, index: number, ticks: any[]) => this.displayTicks(value, index, ticks),
          },
          // 両端に余分なスペースを追加する
          offset: true,
        },
      ]
    } else {
      this.options.scales.xAxes = [
        {
          gridLines: {
            // X軸のボーダーのみを表示 (グリッド線なし)
            tickMarkLength: 0,
            color: '#000000',
            drawOnChartArea: false,
          },
          ticks: {
            padding: 10,
            // 横書き
            maxRotation: 0,
            minRotation: 0,
            fontColor: '#000000',
            fontFamily: 'Noto Sans JP',
            autoSkip: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            callback: (value: string, index: number, ticks: any[]) => this.displayTicks(value, index, ticks),
          },
          // 両端に余分なスペースを追加する
          offset: true,
        },
      ]
    }
    // 縦軸
    this.options.scales.yAxes = [
      {
        gridLines: {
          // Y軸のボーダーのみを表示 (グリッド線なし)
          tickMarkLength: 0,
          color: '#000000',
          drawOnChartArea: false,
        },
        ticks: {
          maxTicksLimit: 6,
          min: this.defaultTemp,
          max: this.max + 1.0,
          padding: 10,
          fontColor: '#000000',
          // 小数点以下0埋め
          callback: (value: string) => `${(+value).toFixed(1)}`,
        },
      },
    ]
  }

  /** 日単位のデータを生成する */
  createDayData(): void {
    let start = moment(this.dateRange, YMD).format(`YYYY/MM/DD 00:00:00`)
    let end = moment(this.dateRange, YMD).add(1, 'days').format(`YYYY/MM/DD 00:00:00`)
    let range = moment.range(moment(start, 'YYYY/MM/DD HH:mm:ss'), moment(end, 'YYYY/MM/DD HH:mm:ss'))
    let array = Array.from(range.by('hours', { step: 6 }))
    this.labels = array.map((m) => m.format('YYYY/MM/DD HH:mm:ss'))
    this.creatDayDataSets()
  }

  /** データから日単位のデータを生成する */
  creatDayDataSets(): void {
    this.averageTemperature = this.data.averageTemperature
    this.isAverageTemperatureVisible =
      DEFAULT_MIN_TEMP <= this.averageTemperature && this.averageTemperature <= DEFAULT_MAX_TEMP
    // Jsonデータ中履歴を取得する
    this.histories = this.data.history
    const dataSet1: ChartPoint[] = []
    this.setYAxisSize()
    this.histories.forEach((h: THistoryInfo) => {
      dataSet1.push({
        x: moment(h.dateTime, 'YYYY/MM/DD HH:mm:ss'),
        y: h.bodyTemp,
      })
    })
    // 実際体温グラフ
    this.datasets.push(this.getDataSet1(dataSet1))
    this.createAverageDataSets(dataSet1)
    this.createIconDataSets(dataSet1)
  }

  /** 週・月単位のデータを生成する */
  createData(): void {
    let date = this.dateRange.split('-')
    let range = moment.range(moment(date[0], YMD), moment(date[1], YMD))
    let array = Array.from(range.by('day'))
    // 改行するために、月/日と曜日を配列化(X軸の値)
    this.labels = array.map((m) => [m.format('M/D'), m.format('(dd)')])
    this.creatDataSets(array)
  }

  /** データから週・月単位のデータを生成する */
  creatDataSets(dateList: MomentOrg.Moment[]): void {
    const xData = dateList.map((m) => m.format('YYYY/MM/DD 00:00:00'))
    this.averageTemperature = this.data.averageTemperature
    this.isAverageTemperatureVisible =
      DEFAULT_MIN_TEMP <= this.averageTemperature && this.averageTemperature <= DEFAULT_MAX_TEMP
    // Jsonデータ中履歴を取得する
    this.histories = this.data.history as THistoryInfo[]
    // 全ての登録日時を配列に格納する
    const allDateTime = this.histories.map((h: THistoryInfo) => h.dateTime)
    this.setYAxisSize()
    const dataSet1: ChartPoint[] = []
    // Jsonデータ中に該当日付の履歴があるかを検索する
    xData.forEach((datetime: string) => {
      // 該当日付の履歴がある
      if (allDateTime.includes(datetime)) {
        const bodyTemps = this.histories.filter((h) => h.dateTime === datetime).map((h) => h.bodyTemp)
        const maxBodyTemp = bodyTemps.length === 0 ? 0 : Math.max(...bodyTemps)
        dataSet1.push({
          x: moment(datetime, 'YYYY/MM/DD 00:00:00'),
          y: maxBodyTemp,
        })
      } else {
        // 該当日付の履歴がない
        dataSet1.push({
          x: moment(datetime, 'YYYY/MM/DD 00:00:00'),
          // プロットしないように0を設定
          y: 0,
        })
      }
    })
    // 実際体温グラフ
    this.datasets.push(this.getDataSet1(dataSet1))
    this.createAverageDataSets(dataSet1)
    // 週単位の場合、問診票アイコンを表示する
    if (this.graphDateScope !== GraphDateScope.MONTH) {
      this.createIconDataSets(dataSet1)
    }
  }

  /** 最低体温、最高体温、デフォルト体温を算出する */
  setYAxisSize(): void {
    // 全ての体温値を配列に格納する
    const allbodyTemp = this.histories.map((h: THistoryInfo) => h.bodyTemp)
    if (allbodyTemp.length > 0) {
      // 最低体温(小数点切り捨て)
      const min = Math.min(...allbodyTemp)
      this.min = Math.floor(
        this.isAverageTemperatureVisible && this.averageTemperature < min ? this.averageTemperature : min
      )
      // 最高体温(小数点切り上げ)
      const max = Math.max(...allbodyTemp)
      this.max = Math.ceil(
        this.isAverageTemperatureVisible && this.averageTemperature > max ? this.averageTemperature : max
      )
      // デフォルト体温
      this.defaultTemp = this.min - 1.0
    } else {
      this.defaultTemp = DEFAULT_TEMP
      this.min = DEFAULT_MIN_TEMP
      this.max = DEFAULT_MAX_TEMP
    }
  }

  /** 実際体温のグラフの設定 */
  getDataSet1(dataSet1: ChartPoint[]): ChartDataSets {
    // ポイント半径
    let pointRadius: number = this.graphDateScope === GraphDateScope.MONTH ? 2 : 9
    return {
      data: dataSet1,
      fill: false,
      showLine: false,
      pointRadius: pointRadius,
      pointHoverRadius: pointRadius,
      pointHoverBackgroundColor: (context: ScriptContext) =>
        context.dataIndex === this.selectedPointIndex ? '#ffffff' : this.getPointBackgroundColor(context),
      pointBackgroundColor: (context: ScriptContext) =>
        context.dataIndex === this.selectedPointIndex ? '#ffffff' : this.getPointBackgroundColor(context),
      pointHoverBorderColor: (context: ScriptContext) => this.getPointBackgroundColor(context),
      pointBorderColor: (context: ScriptContext) => this.getPointBackgroundColor(context),
      pointHoverBorderWidth: 2,
      pointBorderWidth: 2,
    }
  }

  /** 平均体温を計算する(デフォルト体温を除外する) */
  createAverageDataSets(dataSet1: ChartPoint[]): void {
    const average = this.averageTemperature
    if (DEFAULT_MIN_TEMP <= average && average <= DEFAULT_MAX_TEMP) {
      let dataSet2: ChartPoint[] = []
      if (this.graphDateScope !== GraphDateScope.DAY) {
        dataSet1.forEach((data) => {
          dataSet2.push({
            x: data.x,
            y: average,
          })
        })
      } else {
        this.labels.forEach((data) => {
          dataSet2.push({
            x: data.toString(),
            y: average,
          })
        })
      }
      // 平均体温
      this.datasets.push(this.getDataSet2(dataSet2))
    }
  }

  /** 平均体温のグラフの設定（週・月） */
  getDataSet2(dataSet2: ChartPoint[]): ChartDataSets {
    return {
      data: dataSet2,
      fill: false,
      borderColor: '#fc8c04',
      // 直線
      lineTension: 0,
      // データなしの場合、ポイント間に線が引かれる
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 0,
    }
  }

  /** 問診票のアイコンを設定する */
  createIconDataSets(dataSet1: ChartPoint[]): void {
    let dataSet3: ChartPoint[] = []
    dataSet1.forEach((data: ChartPoint) => {
      if (typeof data.y !== 'number') return
      dataSet3.push({
        x: data.x,
        // アイコンをポイントの上に表示するため
        y: this.getIconPointValue(+data.y),
        // 実際の体温
        r: +data.y,
      })
    })
    if (dataSet3.length > 0) {
      // アイコン
      this.datasets.push(this.getWeekDataSet3(dataSet3))
    }
  }

  /** 縦軸の表示範囲による、加算値を取得する */
  getIconPointValue(temperature: number): number {
    let add: string = ((this.max - this.defaultTemp) * 0.15).toFixed(1)
    return temperature + +add
  }

  /**
   * 症状あり・なし
   *
   * @param dateTime 積算日時
   * @returns True：あり false: なし
   */
  withSymptoms(dateTime: string | MomentOrg.Moment | Date): boolean {
    // 症状あり・なしを取得するために
    const historiesInMoment = this.histories.filter(
      (h) => h.dateTime === moment(dateTime).format('YYYY/MM/DD HH:mm:ss')
    )
    if (historiesInMoment.length === 0) {
      return false
    }
    const maxBodyTemp = Math.max(...historiesInMoment.map((h) => h.bodyTemp))
    const historiesByMaxBodyTemp = historiesInMoment.filter((h) => h.bodyTemp === maxBodyTemp)
    if (historiesByMaxBodyTemp.length === 0) {
      return false
    }
    return historiesByMaxBodyTemp[historiesByMaxBodyTemp.length - 1].withSymptoms
  }

  /** 問診票アイコンのグラフの設定（週） */
  getWeekDataSet3(dataSet3: ChartPoint[]): ChartDataSets {
    return {
      data: dataSet3,
      fill: false,
      pointStyle: (context: ScriptContext) => this.getPointIcon(context),
      showLine: false,
      pointRadius: 22,
      pointHoverRadius: 22,
    }
  }

  /**
   * 接触確認アプリ通知あり・なし
   *
   * @param dateTime 積算日時
   * @returns True：あり false: なし
   */
  withContactNotification(dateTime: string | MomentOrg.Moment | Date): boolean {
    let bol = false
    // 通知あり・なしを取得するために
    let history = this.histories.find(
      (h: THistoryInfo) => h.dateTime === moment(dateTime).format('YYYY/MM/DD HH:mm:ss')
    )
    bol = history ? history?.withContactNotification : false
    return bol
  }

  /** アイコンを表示する */
  getPointIcon(context: ScriptContext): HTMLImageElement {
    const element: HTMLImageElement = new Image()
    if (!context.dataset?.data || typeof context.dataIndex !== 'number') return element

    const optPoint = context.dataset.data[context.dataIndex]
    if (!optPoint) return this.pinkIconList[0]
    const point = optPoint as ChartPoint
    if (typeof point.r !== 'number' || !point.x || typeof point.x === 'number') return element

    // 実際の体温
    const temperature = +point.r
    // 症状あり・なし
    const withSymptoms = this.withSymptoms(point.x)
    // 問診票登録状況
    const withInterview = this.isRecorded(point.x)
    // 接触者確認アプリ通知あり・なし
    const withContactNotification = this.withContactNotification(point.x)

    const selectIcon = (icons: HTMLImageElement[]): HTMLImageElement => {
      if (withSymptoms && withInterview && withContactNotification) return icons[5]
      else if (withSymptoms && withInterview && !withContactNotification) return icons[3]
      else if (withSymptoms && !withInterview && withContactNotification) return icons[4]
      else if (withSymptoms && !withInterview && !withContactNotification) return icons[1]
      else if (!withSymptoms && withInterview && withContactNotification) return icons[2]
      else if (!withSymptoms && withInterview && !withContactNotification) return icons[0]
      else if (!withSymptoms && !withInterview && withContactNotification) return icons[6]
      else return element
    }

    if (temperature >= 37.5) {
      return selectIcon([...this.pinkIconList, ...this.blackIconList])
    } else if (
      (temperature >= 37.0 && temperature <= 37.4) ||
      (this.averageTemperature > 0 && temperature >= this.averageTemperature + 1.0)
    ) {
      // (黄)37.0～37.4℃、または、平均+1度以上 (平熱が0度の場合は、体温と平熱を比較しない)
      return selectIcon([...this.yellowIconList, ...this.blackIconList])
    } else if (temperature > this.defaultTemp && temperature <= 36.9) {
      return selectIcon([...this.blueIconList, ...this.blackIconList])
    } else {
      return element
    }
  }

  /** X軸の値の表示 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayTicks(value: string, index: number, ticks: any[]): string {
    // 月単位の場合、X軸は三つの値のみ表示
    if (this.graphDateScope === GraphDateScope.MONTH) {
      let middle: number = Math.floor(ticks.length / 2)
      let last: number = ticks.length - 1
      if ([0, middle, last].includes(index)) {
        return value
      }
      return ''
    } else {
      return value
    }
  }

  /**
   * 問診票登録状況
   *
   * @param dateTime 積算日時
   * @returns True：登録済 false: 未登録
   */
  isRecorded(dateTime: string | MomentOrg.Moment | Date): boolean {
    const historiesInMoment = this.histories.filter(
      (h) => h.dateTime === moment(dateTime).format('YYYY/MM/DD HH:mm:ss')
    )
    if (historiesInMoment.length === 0) {
      return false
    }
    const maxBodyTemp = Math.max(...historiesInMoment.map((h) => h.bodyTemp))
    const historiesByMaxBodyTemp = historiesInMoment.filter((h) => h.bodyTemp === maxBodyTemp)
    if (historiesByMaxBodyTemp.length === 0) {
      return false
    }
    return !!historiesByMaxBodyTemp[historiesByMaxBodyTemp.length - 1].medicalInterviewId
  }
}

/** 期間取得拡張 */
const moment = extendMoment(MomentOrg)
moment.locale('ja')

/** デフォルト体温値 */
const DEFAULT_TEMP = 32.0
const DEFAULT_MIN_TEMP = 33.0
const DEFAULT_MAX_TEMP = 44.0

/** デフォルトデータ */
const DEFAULT_DATA = { history: [], averageTemperature: 0 }

const YMD = 'YYYY/MM/DD'

type ScriptContext = {
  chart?: Chart
  dataIndex?: number
  dataset?: ChartDataSets
  datasetIndex?: number
}

const PointPinkImageList = [
  require('@/assets/images/icon/chart/point/pink/interview.svg'),
  require('@/assets/images/icon/chart/point/pink/symptoms.svg'),
  require('@/assets/images/icon/chart/point/pink/interview_contact.svg'),
  require('@/assets/images/icon/chart/point/pink/interview_symptoms.svg'),
  require('@/assets/images/icon/chart/point/pink/contact_symptoms.svg'),
  require('@/assets/images/icon/chart/point/pink/interview_contact_symptoms.svg'),
]
const PointYellowImageList = [
  require('@/assets/images/icon/chart/point/yellow/interview.svg'),
  require('@/assets/images/icon/chart/point/yellow/symptoms.svg'),
  require('@/assets/images/icon/chart/point/yellow/interview_contact.svg'),
  require('@/assets/images/icon/chart/point/yellow/interview_symptoms.svg'),
  require('@/assets/images/icon/chart/point/yellow/contact_symptoms.svg'),
  require('@/assets/images/icon/chart/point/yellow/interview_contact_symptoms.svg'),
]
const PointBlueImageList = [
  require('@/assets/images/icon/chart/point/blue/interview.svg'),
  require('@/assets/images/icon/chart/point/blue/symptoms.svg'),
  require('@/assets/images/icon/chart/point/blue/interview_contact.svg'),
  require('@/assets/images/icon/chart/point/blue/interview_symptoms.svg'),
  require('@/assets/images/icon/chart/point/blue/contact_symptoms.svg'),
  require('@/assets/images/icon/chart/point/blue/interview_contact_symptoms.svg'),
]
const PointBlackImageList = [require('@/assets/images/icon/chart/point/black/contact.svg')]























import { Component, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import ConditionTable from '@/components/organisms/Person/ConditionTable.vue'
import ConditionChart from '@/components/organisms/Person/ConditionChart.vue'
import Csv from '@/components/organisms/Person/Csv.vue'
import menuStore from '@/store/Menu'
import conditionsStore from '@/store/Conditions'
import symptomsStore from '@/store/Symptoms'

@Component({
  components: { ConditionTable, ConditionChart, Csv },
})
export default class extends Vue {
  @Watch('$route')
  async beforeRouteUpdate(): Promise<void> {
    try {
      await this.fetch()
      this.redirectIfCantFetchGroup()
    } catch (error) {
      console.error(error)
      this.$router.replace({ name: 'Home' })
    }
  }

  async mounted(): Promise<void> {
    try {
      await this.fetch()
      this.redirectIfCantFetchGroup()
    } catch (error) {
      console.error(error)
      this.$router.replace({ name: 'Home' })
    }
  }

  private async fetch(): Promise<void> {
    menuStore.setActiveFlg({ home: false, person: true, group: false })
    conditionsStore.setConditions([])
    symptomsStore.setSymptoms([])

    await Promise.all([symptomsStore.fetch()])

    // サイドメニューの設定
    await menuStore.fetchGroupAndUsers({ groupId: this.$route.params.groupId, personId: this.$route.params.id })
  }

  redirectIfCantFetchGroup(): void {
    const user = _.find(menuStore.persons, { active: true })
    if (!user) this.$router.replace({ name: 'Home' })
  }
}

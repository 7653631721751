















































import { Component, Vue } from 'vue-property-decorator'
import conditionsStore from '@/store/Conditions'
import Datepicker from 'vuejs-datepicker'
import Spinner from '@/components/atoms/Spinner.vue'
import Modal from '@/components/molecules/Modal.vue'
import { ja } from 'vuejs-datepicker/dist/locale'
import dayjs from '@/libs/dayjs'
import Csv from '@/libs/Csv'
import { TSymptom } from '@/store/Conditions'

@Component({
  components: { Spinner, Datepicker, Modal },
})
export default class extends Vue {
  // モーダル処理
  $fixApp!: (fixed: boolean) => void
  isOpen = false
  modalClose(): void {
    this.isOpen = false
    this.$fixApp(false)
  }
  modalOpen(): void {
    this.isOpen = true
    this.$fixApp(true)
  }

  // CSV処理
  isLoading = false
  ja = ja
  fromDate = dayjs().subtract(1, 'M').add(1, 'd').format('YYYY/MM/DD')
  toDate = dayjs().format('YYYY/MM/DD')

  /** カレンダーのフォーマット */
  datepickerFormatter(date: string): string {
    return dayjs(date).format('YYYY/MM/DD')
  }
  // NOTE: template内で使用
  /** カレンダーの選択範囲制限 */
  datepickerSelectionLimit = { to: dayjs().subtract(1, 'y').toDate(), from: dayjs().toDate() }

  /** CSVダウンロード処理 */
  async downloadCSV(): Promise<void> {
    const startDate = dayjs(this.fromDate)
    const endDate = dayjs(this.toDate)
    const userIds = [this.$route.params.id]
    this.isLoading = true
    await conditionsStore.fetchByRange({ startDate, endDate, userIds }).finally(() => (this.isLoading = false))

    const csvHeader = [
      'ID',
      '氏名',
      '日付・時刻',
      '体温(体調情報)',
      '症状(体調情報)',
      'メッセージ',
      '発熱',
      '体温(問診票情報)',
      '平熱',
      '症状(問診票情報)',
      '最終出勤日',
      '通勤手段（選択）',
      '通勤手段（詳細）',
      '受診医療機関名',
      'PCR検査（有無）',
      'PCR検査（詳細）',
      '感染者と接触有無',
      '感染者接触（詳細）',
      '県外移動（有無）都道府県外移動、海外渡航 履歴',
      '県外移動（詳細：誰が）',
      '県外移動（詳細：場所）',
      '県外移動（期間）',
      '人の過密場所への移動(有無)',
      '人の過密場所への移動（詳細：誰が）',
      '人の過密場所への移動（詳細：場所）',
      '人の過密場所への移動(期間表示）',
    ]
    const csvData = conditionsStore.csvData.map((condition, i) => {
      const medicData = condition.medical_interview?.medical_interview_data
      return [
        // ID
        i + 1,
        // 氏名
        condition.user.lastName + ' ' + condition.user.firstName,
        // 日付・時刻
        condition.date.format('YYYY/MM/DD HH:mm:ss'),
        // 体温(体調情報)
        condition.body_temp.toFixed(1),
        // 症状(体調情報)
        this.getSymptoms(condition.symptoms),
        // メッセージ
        condition.message,
        // 発熱
        medicData?.fever.flag,
        // 体温(問診票情報)
        medicData?.fever.temperature,
        // 平熱
        medicData?.fever.averageTemperature,
        // 症状(問診票情報)
        this.getSymptoms(medicData?.symptoms),
        // 最終出勤日
        medicData?.workday.isValid() ? medicData?.workday.format('YYYY/MM/DD') : '',
        // 通勤手段（選択）
        this.getMeans(medicData?.commuting),
        // 通勤手段（詳細）
        medicData?.commuting.detail,
        // 受診医療機関名
        medicData?.hospital,
        // PCR検査（有無）
        medicData?.pcr.flag,
        // PCR検査（詳細）
        medicData?.pcr.detail,
        // 感染者と接触有
        medicData?.accompany.flag,
        // 感染者接触（詳細）
        medicData?.accompany.person,
        // 県外移動（有無）都道府県外移動、海外渡航 履歴
        medicData?.travel.flag,
        // 県外移動（詳細：誰が）
        medicData?.travel.person,
        // 県外移動（詳細：場所）
        medicData?.travel.place,
        // 県外移動（期間）
        this.getTravelDateRange(medicData?.travel),
        // 人の過密場所への移動(有無)
        medicData?.gathering.flag,
        // 人の過密場所への移動（詳細：誰が）
        medicData?.gathering.person,
        // 人の過密場所への移動（詳細：場所）
        medicData?.gathering.place,
        // 人の過密場所への移動(期間表示）
        this.getTravelDateRange(medicData?.gathering),
      ]
    })

    const csv = Csv.create(csvHeader, csvData)

    // CSVデータ表示処理
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
    link.download = 'Conditions.csv'
    link.click()
  }

  /**
   * 症状取得
   *
   * 全種類の症状の中から発症しているものだけを抽出して「/」区切りで表示
   */
  private getSymptoms(symptoms?: TSymptom[]): string {
    return (
      symptoms
        ?.filter((v) => v.onset)
        .map((v) => v.name)
        .join('/') || ''
    )
  }

  /** 移動期間取得 */
  private getTravelDateRange(travel?: { traveldatefrom: string; traveldateto: string }): string {
    return `${travel?.traveldatefrom ?? ''}-${travel?.traveldateto ?? ''}`
  }

  /** 交通手段の取得 */
  private getMeans(commuting?: { means: number; other: boolean }): string {
    const meansOther = commuting?.other
    const meansNum = commuting?.means as 0 | 1 | 2 | 3 | 4 | 5 | undefined
    if (meansOther) {
      return 'その他'
    } else if (meansNum) {
      const commutingList = {
        1: '車',
        2: '自転車',
        3: 'バス',
        4: '電車',
        5: '徒歩',
      }
      return commutingList[meansNum]
    } else {
      return ''
    }
  }
}
